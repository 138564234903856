import React from "react"
import Slider from "react-slick"
import { Button } from "@material-ui/core"
import styled from "styled-components"
import { FaMapMarkerAlt } from "react-icons/fa"
import "../scss/slider.css"
import star from "../images/icons/star.png"
import starb from "../images/icons/starb.png"
import moment from "moment"
import { Link } from "gatsby"

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: false,
  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 946,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
  ],
}

export default function (props) {
  const getImageName = (name) => {
    let url = name
    url = name.replace(/ /g, "_")
    return url
  }

  const hotelList = (hotelList) => {
    return (
      hotelList &&
      hotelList.map((hotel) => {
        const stars_jsx = []
        const full_stars = Math.floor(+hotel.stars)
        let { cityCode, startDate, endDate, currency } = props

        for (let i = 1; i <= 5; ++i) {
          if (i <= full_stars) {
            stars_jsx.push(<img className="startImage" src={star} />)
          } else {
            stars_jsx.push(<img className="startImage" src={starb} />)
          }
        }
        startDate = startDate || moment().add(14, "days").format("YYYY-MM-DD")
        endDate = endDate || moment().add(21, "days").format("YYYY-MM-DD")
        return (
          <div
            className="hotel-outer"
            key={hotel.name}
            onClick={() =>
              window.open(
                `${process.env.TRAVALA_URL}/hotels?adults=1&city=${cityCode}&from=${startDate}&kids=0&kidsAges=%5B%5D&limit=15&page=1&rooms=1&sortBy=stars&to=${endDate}`,
                "_blank"
              )
            }
          >
            <div className="container">
              <div className="hotel">
                <div className="hotelImage">
                  <img
                    alt={hotel.name}
                    src={
                      hotel.details &&
                      hotel.details.images[0] &&
                      hotel.details.images[0].path
                    }
                  />
                </div>
                <div className="hotel-content">
                  <h3 className="hotel-name">{hotel.name}</h3>
                  <span className="hotel-city">
                    <FaMapMarkerAlt className="hotel-marker" />
                    {` ` + hotel.city}
                  </span>
                  <div className="flexArrangeRow">{stars_jsx}</div>
                </div>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  const otherCityList = (otherCityList) => {
    return (
      otherCityList &&
      otherCityList.map((city) => {
        return (
          <div className="hotel-outer">
            <div className="container explore-city-container">
              <div className="hotel">
                <div className="hotelImage">
                  {city.heroImage && (
                    <img alt={city.slug} src={city.heroImage.file.url} />
                  )}
                </div>
                <div className="hotel-content">
                  <h3 className="hotel-name">{city.title}</h3>
                  <div className="flexArrangeRow">
                    {city.description.description}
                  </div>
                  <div className="explore-btn">
                    <Link to={"/flightsto" + city.slug}>
                      {" "}
                      Explore<span className="city-name">{city.title}</span>
                    </Link>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })
    )
  }
  return (
    <HotelContainer>
      <div className="hotels-heading">
        {props.listName == "otherCityList" ? (
          <div className="hotel-heading center">
            Whilst you here, why not also explore…
          </div>
        ) : (
          <span className="hotel-heading">{props.title}</span>
        )}
      </div>
      <Slider {...settings}>
        {eval(props.listName || "hotelList")(props.list)}
      </Slider>
    </HotelContainer>
  )
}

const HotelContainer = styled.div`
  margin-bottom: 5em;
  margin: auto;
  padding-bottom: 2em;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  .slick-dots {
    position: absolute;
    bottom: 2%;
    cursor: pointer;
  }

  .slick-dots li button:before {
    font-size: 12px;
    cursor: pointer;
  }

  .slick-dots li.slick-active button:before {
    color: #1f0c63;
    cursor: pointer;
  }

  .hotel-outer {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    margin: opx !important;
    cursor: pointer;
  }

  .hotels-heading {
    display: flex;
    justify-content: space-between;
    .hotel-heading {
      font-family: ProximaNova;
      font-size: 22px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      text-align: left;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 2%;
    }
    .center {
      text-align: center;
      width: 100%;
    }
  }

  .startImage {
    height: 18px;
  }

  .container {
    width: 244px !important;
    height: auto;
    border-radius: 16px;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    margin-bottom: 3em !important;
  }

  .explore-city-container {
    height: auto !important;
  }

  .hotel {
    border-radius: 5px;
    .hotel-content {
      margin-top: 0px !important;
      padding: 16px;
      .hotel-name {
        min-height: 44px !important;
        margin-top: 0px;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0, 0, 0, 0.87);
        font-family: ProximaNova;
      }
      .hotel-marker {
        opacity: 0.6;
        font-size: 10.5px;
      }
      .hotel-city {
        font-family: ProximaNova;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.53;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
      }
      .hotel-fare-from {
        font-family: ProximaNova;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
        text-align: right;
        color: #1f0c63;
      }
    }

    .slick-active {
      background-color: #1f0c63;
      width: 9px;
      height: 9px;
    }

    .hotelImage {
      height: 163px;
    }

    .hotelImage img {
      width: 244px !important;
      height: 163px !important;
      border-radius: 16px 16px 0px 0px;
      object-fit: cover !important;
    }

    .startImage {
      height: 18px;
      width: 18px !important;
    }

    .flexArrangeRow {
      display: flex;
      margin-top: 1%;
    }
    .explore-btn {
      margin: auto;
      margin-top: 10px;
      padding: 4px;
      border-radius: 20px;
      border: solid 1px rgba(0, 0, 0, 0.12);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: ProximaNova;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: normal;
      color: #f66415;
      .city-name {
        font-weight: bold;
        margin-left: 5px;
      }
    }
  }
`
