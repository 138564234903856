import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import Page from "../components/flights/page"
import api from "../api"
import { FaArrowRight } from "react-icons/fa"
import { graphql } from "gatsby"
import moment from "moment"
import UserContext from "../components/userContext"
import TilesSlider from "../components/tilesSlider"
import SEO from "../components/seo"
import plane from "../images/icons/plane.svg"
import fb from "../images/icons/fb.png"
import twitter from "../images/icons/twitter.png"
import "../scss/destinations.scss"

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulDestination(slug: { eq: $slug }) {
      title
      slug
      heroImage {
        file {
          url
        }
      }
      description {
        description
      }
      body {
        body
      }
      header
      cityCode
      flightId {
        city
        amount
        btc
        cityCode
      }
      faq {
        que
        ans
      }
      popularDestinations {
        cityCode
        title
        description {
          description
        }
        slug
        heroImage {
          file {
            url
          }
        }
      }
      mustSeeAndDo {
        line
      }
    }
  }
`

const FlightRows = (props) => {
  const from = moment().add(1, "days").format("YYYY-MM-DD")
  const to = moment().add(21, "days").format("YYYY-MM-DD")

  return (
    <FlightRowWrapper
      onClick={() =>
        window.open(
          `${process.env.TRAVALA_URL}/flights?adults=1&depart=${from}&from=${props.cityCode}&return=${to}&sort=best&to=${props.toCityCode}&class=Economy&sortBy=best&bookingStep=outbound&infants=0&kids=0&limit=10&page=1`,
          "_blank"
        )
      }
    >
      <div className="outer">
        <div
          className="box"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            marginLeft: "2%",
          }}
        >
          <div>
            <img style={{ maxHeight: "70vh" }} src={plane} />
          </div>
          <div
            style={{
              display: "flex",
              flexGrow: "1",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "4%",
            }}
          >
            <p className="black-heading"> {props.city} </p>
            <p className="return">Return </p>
          </div>
        </div>

        <div className="box">
          <div>
            <p className="blue-text">from ${props.amount}</p>
            <p className="btc">{props.btc} BTC </p>
          </div>
        </div>
      </div>
      {!props.isLast && (
        <div
          style={{ width: "95%", border: "1px solid #e9e9e9", margin: "auto" }}
        >
          {" "}
        </div>
      )}
    </FlightRowWrapper>
  )
}

const PopularDestination = (props) => {
  return (
    <PopularDestinationWrapper
      onClick={() => window.open(`/${props.place.slug}`, "_blank")}
    >
      <span className="flightsName">{props.place.name}</span>
      <FaArrowRight
        style={{ width: "20px", height: "16px", color: "#ef6c00" }}
      />
    </PopularDestinationWrapper>
  )
}

const DestinationContent = ({ data }) => {
  const [hotels, setHotels] = useState([])
  const { userData } = useContext(UserContext)

  const {
    title,
    body,
    heroImage,
    startDate,
    endDate,
    description,
    flightId,
    faq,
    slug,
    mustSeeAndDo,
    popularDestinations,
    cityCode,
  } = data.contentfulDestination

  const fetchHotels = async () => {
    let hotels_data = await Promise.all(
      data.contentfulDestination.hotels.map((hotel) =>
        api.get(`/hotels/info/${hotel.hotelId}`).then(({ data }) => {
          return {
            ...data,
            price: hotel["amount"],
          }
        })
      )
    )

    setHotels(hotels_data)
  }

  useEffect(() => {
    fetchHotels()
  }, [])

  let destinationArray = []

  return (
    <>
      <SEO title={`Destinations | ${title}`} />
      <StyledPage>
        <div className="container destination-flights ">
          <div className="destination-image">
            {heroImage && <img className="hero" src={heroImage.file.url} />}
          </div>
          <div className="blurb">
            <div
              className="social"
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.FRONTEND_URL}/${slug}`}
                target="_blank"
              >
                <span
                  className="socialRing"
                  style={{ display: "flex", marginTop: "5%" }}
                >
                  <img className="fb" src={fb} />
                </span>
              </a>
              <a
                href={`https://twitter.com/home?status${process.env.FRONTEND_URL}/${slug}`}
                target="_blank"
              >
                <span
                  className="socialRing"
                  style={{ display: "flex", marginTop: "15%" }}
                >
                  <img className="twitter" src={twitter} />
                </span>{" "}
              </a>
            </div>
            <div className="text-content">
              <div className="cheap-hotels-in">CHEAP FLIGHTS TO </div>
              <div className="country">{title}</div>
              <div className="cheap-hotels-in">{body.body}</div>
            </div>
          </div>
          {flightId && flightId.length > 0 && (
            <div className="flights">
              <div
                className="blurb"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <h3 className="flightsHeading">Flights deals from {title}</h3>
                <div className="flight-avaliables">
                  {flightId.map((row, index) => {
                    return (
                      <FlightRows
                        city={row.city}
                        amount={row.amount}
                        toCityCode={row.cityCode}
                        index={index}
                        btc={row.btc}
                        cityCode={cityCode}
                        isLast={index == flightId.length - 1}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          )}

          {destinationArray && destinationArray.length != 0 && (
            <div className="flights">
              <div
                className="blurb"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                <h3 className="flightsHeading">
                  Popular destinations in {title}
                </h3>
                <div className="popular-destination">
                  {destinationArray &&
                    destinationArray.map((data) => {
                      return <PopularDestination place={data} />
                    })}
                </div>
              </div>
            </div>
          )}
          <div className="flights-hotels">
            <div
              className="blurb"
              style={{ flexDirection: "column", alignItems: "flex-start" }}
            >
              {mustSeeAndDo && mustSeeAndDo.length && (
                <div className="must-see">
                  <div style={{ flexGrow: 1, flexBasis: "30%" }}>
                    <h3 className="flightsHeading" style={{ display: "flex" }}>
                      Must See and Do
                    </h3>
                  </div>
                  <div
                    style={{ flexGrow: 1, marginTop: "2%", flexBasis: "70%" }}
                  >
                    <ol className="mustList">
                      {mustSeeAndDo.map((line) => (
                        <li className="blockBullet">{line.line}</li>
                      ))}
                    </ol>
                  </div>
                </div>
              )}
              {faq && faq.length && (
                <div className="must-see">
                  <div style={{ flexGrow: 1, flexBasis: "30%" }}>
                    <h3 className="flightsHeading" style={{ display: "flex" }}>
                      Frequently asked question about {title}
                    </h3>
                  </div>
                  <div
                    style={{ flexGrow: 1, marginTop: "2%", flexBasis: "70%" }}
                  >
                    <ol className="mustList">
                      {faq.map((faq) => (
                        <>
                          <div class="numberCircle">Q</div>
                          <li className="queBullet">{faq.que}</li>
                          <div class="numberCircle">A</div>
                          <li className="ansBullet">{faq.ans}</li>
                        </>
                      ))}
                    </ol>
                  </div>
                </div>
              )}
              <NewsLetter>
                <NewsLetterHeading>Never Miss a deal again!</NewsLetterHeading>
                <NewsLetterHeading
                  style={{ fontSize: "16px", fontWeight: "normal" }}
                >
                  Enter your email address to receive exclusive offers, travel
                  inspiration and trending destinations delivered straight to
                  your inbox.
                </NewsLetterHeading>
                <br />
                <form
                  target="_blank"
                  action="https://app.getresponse.com/add_subscriber.html"
                  method="POST"
                  accept-charset="utf-8"
                >
                  <ButtonContainer>
                    <EmailInput
                      placeholder="Enter your email address"
                      name="email"
                    />
                    <input type="hidden" name="campaign_token" value="B9bgN" />
                    <input type="hidden" name="start_day" value="0" />
                    <NewButton style={styles.subscribeButton}>
                      Subscribe
                    </NewButton>
                  </ButtonContainer>
                </form>
              </NewsLetter>
            </div>
          </div>
          {popularDestinations && popularDestinations.length && (
            <div className="otherCity">
              <div style={{ flexDirection: "column" }}>
                <TilesSlider
                  listName={"otherCityList"}
                  list={popularDestinations}
                  currency={userData.userCurrency}
                />
              </div>
            </div>
          )}
        </div>
      </StyledPage>
    </>
  )
}

export default DestinationContent

const StyledPage = styled(Page)``

const FlightRowWrapper = styled.div`
  cursor: pointer;
  font-family: "ProximaNova";
  .outer {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .return {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-family: "ProximaNova";
    font-weight: normal;
  }
  .box {
    flex-direction: row;
    padding: 10px 10px;
    margin-right: 2%;
    width: inherit;
  }

  .blue-text {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: right;
    color: #1f0c63;
  }
  .black-heading {
    font-family: ProximaNova;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
  }
  .btc {
    font-family: ProximaNova;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: rgba(0, 0, 0, 0.38);
  }
`

const PopularDestinationWrapper = styled.div`
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  width: 49%;
  margin-bottom: 1%;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
  .flightsName {
    font-size: 18px;
    font-weight: 600;
    font-family: ProximaNova;
    color: rgba(0, 0, 0, 0.87);
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
  margin-top: 10px;
`

const NewsLetter = styled.div`
  border-radius: 8px;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 32px;
  width: 100%;
  text-align: left;
`

const NewsLetterHeading = styled.div`
  align: left;
  font-family: ProximaNova;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.87);
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const EmailInput = styled.input`
  padding-left: 10px;
  margin-right: 32px;
  margin-bottom: 10px;
  width: 72%;
  height: 48px;
  border-radius: 8px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  backgroundcolor: #ffffff;
  font-family: ProximaNova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  @media only screen and (max-width: 1070px) {
    width: 65%;
    margin-right: 32px;
  }
  @media only screen and (max-width: 805px) {
    width: 55%;
    margin-right: 32px;
  }
  @media only screen and (max-width: 660px) {
    width: 100%;
    margin-right: 0px;
    font-size: 13px;
  }
`
export const NewButton = styled.button`
  height: 50px;
  width: 250px;
  background: orange;
  border: none;
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  /* z-index: 9; */

  &:hover {
    cursor: pointer;
  }
`
const styles = {
  subscribeButton: {
    height: "48px",
    width: "200px",
    backgroundColor: "#ef6c00",
    fontFamily: "ProximaNova",
    fontSize: "18px",
    fontWeight: 600,
    borderRadius: "8px",
  },
}
