import styled from "styled-components"
import theme from "../../theme"
import "../../components/layout.css"

const Page = styled.div`
  min-height: calc(100vh - 65px);
  color: #333;
  .hero {
    width: 100%;
    object-fit: cover;
    max-height: 70vh !important;
  }

  .socialRing {
    width: 32px;
    height: 32px;
    border: solid 1px rgba(0, 0, 0, 0.6);
    border-radius: 30px;
    .fb {
      width: 8px;
      height: 15px;
      margin-left: 35%;
      margin-top: 27%;
    }
    .twitter {
      width: 15px;
      height: 12px;
      margin-left: 27%;
      margin-top: 34%;
    }
  }

  .outer {
    margin-bottom: 1%;
  }

  .container {
    max-width: 920px;
    margin: 0 auto;
  }
  .destination-flights {
    width: 100%;
    margin-top: -2em;
    max-width: none;
  }
  .heading {
    text-align: center;
    margin-bottom: 2.5rem;
  }

  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 1.7em;
    margin-bottom: 1.2rem;
  }

  h3 {
    font-size: 22px;
  }

  li {
    text-align: left;
  }

  h1,
  h2,
  h3 {
    font-family: Ubuntu, ProximaNova, Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #414042;
  }

  a {
    color: ${theme.orange};
    text-decoration: none;
  }

  p,
  a {
    font-size: 1.1em;
  }

  .destination-image {
    width: 100%;
    margin: 0 auto;
  }

  .flights {
    background-color: #f9f9f9;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .blurb {
    text-align: center;
    max-width: 80%;
    margin: 30px auto;
    font-size: 25px;
    font-weight: 200;
    line-height: 1.6666;
    display: flex;
    .social {
      width: 10%;
    }

    .popular-destination {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .flightsHeading {
      font-weight: 600;
      font-family: ProximaNova;
      font-size: 22px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 1%;
      padding-right: 68px;
      text-align: left !important;
    }

    .text-content {
      width: 75%;
      .cheap-hotels-in {
        font-family: "ProximaNova";
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.44;
        letter-spacing: 0.9px;
        text-align: left;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 10px;
      }
      .country {
        font-family: "ProximaNova";
        font-size: 48px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 40px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .topMargin {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .blurb {
      padding: 0 1rem;
      width: 100%;
      max-width: none;
      text-align: center;
      .social {
        display: none;
        margin-left: 35px;
      }
      .flightsHeading {
        font-size: 18px;
      }
      .text-content {
        width: 100%;
        .country {
          font-size: 26px;
          text-align: left;
          line-height: 1.1;
        }
        .cheap-hotels-in {
          font-size: 18px;
          text-align: left;
        }
      }
    }
  }

  .spacerBT {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .topMargin {
    margin-left: -40px;
    margin-right: 25px;
    margin-top: 5%;
  }

  .flights-hotels {
    h2 {
      &:nth-child(3) {
        margin-top: 2.5rem;
      }
    }

    h3 {
      margin-top: 2.5rem;
      text-align: center;
    }
  }

  .mustList {
    font-family: ProximaNova;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    list-style: none;
    color: rgba(0, 0, 0, 0.87);
    padding-top: 2%;
  }

  ol {
    list-style-position: inside;
    margin-bottom: 2rem;

    li {
      margin-bottom: 1.2rem;
    }
  }

  .travel-agent-cta {
    margin: 3em auto 4em auto;
    text-align: center;
  }

  li.blockBullet::before {
    content: "\\2610";
    margin-left: -40px;
    margin-right: 25px;
  }

  .numberCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 2px;
    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    position: absolute;
    margin-left: -50px;
  }
  .must-see {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (max-width: 600px) {
    .destination-flights {
      margin-top: 0;
    }
    .must-see {
      flex-direction: column;
      margin-left: 35px;
    }
  }
`

export default Page
